<template>
    <div :id="blok._uid"
        class="bg-[color:var(--background-color)] px-sm"
        :class="{
            'space-y-2xl': !titleHidden,
            'py-md': !titleHidden || isFilled(blok.ingress)
        }"
        :style="{ '--background-color': blok.background_color?.color }">
        <div v-if="(blok.title && blok.title.length > 0)"
            class="mx-auto text-center text-[color:var(--color)]"
            :class="titleHidden ? 'sr-only' : 'px-sm'"
            :style="{ '--color': blok.text_color?.color }">
            <v-h1 v-if="blok.title && tagSize === 'h1'">
                {{ addHyphens(blok.title) }}
            </v-h1>

            <v-h2 v-else-if="blok.title && tagSize === 'h2'">
                {{ addHyphens(blok.title) }}
            </v-h2>

            <v-h3 v-else-if="blok.title && tagSize === 'h3'">
                {{ addHyphens(blok.title) }}
            </v-h3>
        </div>

        <div v-if="isFilled(blok.ingress)"
            class="prose break-words text-center text-[color:var(--color)]"
            :style="{ '--color': blok.text_color?.color }">
            <sb-rich-text :document="blok.ingress" />
        </div>
    </div>
</template>

<script setup lang="ts">
import type { BlokComponent } from '~~/types';

const properties = defineProps<{
    blok: Extract<BlokComponent, { component: 'sb-title' }>
}>();

const titleHidden = computed(() => properties.blok.hide_title ?? false);
const tagSize = computed(() => properties.blok.title_tag ?? '');

const { hyphenate: addHyphens } = useHyphenation();
const { isFilled } = useSbProse();
</script>
